const config = {
  mapboxToken:
    'pk.eyJ1IjoiaXZhbnZhbmRlcmJ5bCIsImEiOiJjbDJ6Zm1heGEwMmlyM2VvNXBwa2Z6MzRnIn0.joKjgNJmW0jpYeGsOCGxJg',
  googleMapsToken: 'AIzaSyCoOHxrHuX3PBGcgcRxPXNnCFB8r3_JxOM',
  stripePublishableKey:
    process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_hhNgqdiszRPZ17ZWefsX985800uUH3EEev',
  searchDatasetPrefix: process.env.SEARCH_DATASET_PREFIX || 'development',
  typesenseApiKey: process.env.TYPESENSE_API_KEY || 'ItKGneSXwt73XvMonEwHlDnXwBUT5cy4', // 'YiIBL8sumjqq96EOMZdwuVau1kuDpdgN',
  intercomAppId: 'qn34ls9b',
  sentryDSN: 'https://6a43064cdce245f3a66f2e4ae3630618@o377735.ingest.sentry.io/6456158',
}

export type Config = typeof config
export default config
